<template>
  <div class="shadow-card">
    <div class="title" v-if="title">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="less" scoped>
.shadow-card {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 28px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 24px 36px rgba(0, 0, 0, 0.04),
    0px 16px 32px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 1;
  .title {
    margin-bottom: 22px;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
}
</style>
