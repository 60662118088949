<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :size="1000"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>组织范围内共享默认编辑</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button type="primary" round @click="handleSave" :loading="saving"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <cond-card>
          <el-table :data="tableData" class="table-customer">
            <el-table-column
              label="对象"
              width="385px"
              prop="Name"
            ></el-table-column>
            <el-table-column label="内部默认访问权限">
              <template slot-scope="{ row }">
                <el-select
                  v-model="row.OWDInternal"
                  placeholder="请选择"
                  style="width: 440px"
                  :disabled="row.disabledEdit"
                  :title="row.disabledEdit ? '子对象不能编辑权限' : ''"
                >
                  <el-option
                    v-for="item in InternalOptions"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </cond-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import condCard from "@/components/cond-card";
import { apiEditOrgScope } from "@/api/auth";

export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formRule: {
        name: {
          required: true,
          message: "请输入用户名称",
          trigger: "blur",
        },
      },
      tableData: [{}],
      InternalOptions: [], // 权限组列表 ， 如 [{Label: '公用读', Value: 'PublicReadOnly'}]
    };
  },
  computed: {},
  methods: {
    setFormData(data, InternalOptions) {
      this.tableData = data || [];
      this.InternalOptions = InternalOptions || [];
      this.InternalOptions = this.InternalOptions.filter((item) => {
        return item.Value !== "ControlledByParent";
      });
    },
    // 关闭弹窗
    handleClose() {
      if (this.saving) {
        return;
      }
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.saving = true;
      const param = {
        InternalList: [],
      };
      this.tableData.forEach((item) => {
        param.InternalList.push({
          _id: item._id,
          OWDInternal: item.OWDInternal,
        });
      });

      apiEditOrgScope(param)
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }
  .edit-form {
    width: 440px;
  }
}
</style>
