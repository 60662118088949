<template>
  <div>
    <content-header :title="title" :breadList="['系统设置', '共享设置']">
    </content-header>
    <div class="tip">
      此页面会显示贵组织的共享设置。这些设置指定每个用户对于其他每个人的数据所拥有的访问权限级别。子对象继承父对象的共享设置。
    </div>
    <div v-loading="loading">
      <cond-card>
        <div class="card-header">
          <div class="card-header-title">组织范围内默认共享设置</div>
          <div class="card-header-btn">
            <el-button
              type="primary"
              size="medium"
              round
              @click="handleEditSetting"
              >编辑</el-button
            >
          </div>
        </div>
        <el-table :data="InternalList" class="table-customer">
          <el-table-column label="对象" prop="Name"></el-table-column>
          <el-table-column
            label="内部默认访问权限"
            prop="InternalName"
          ></el-table-column>
        </el-table>
      </cond-card>
      <cond-card>
        <div class="card-header">
          <div class="card-header-title">共享规则</div>
          <div class="card-header-btn">
            <el-button
              type="primary"
              size="medium"
              round
              @click="handleAddRules"
              >新建</el-button
            >
          </div>
        </div>
        <!-- 筛选条件 -->
        <div class="query-form" v-if="showQuery">
          <i class="el-icon-close" @click="showQuery = false"></i>
          <el-form
            :inline="true"
            v-if="showQuery"
            label-width="100px"
            ref="ruleForm"
            :model="formData"
          >
            <el-form-item label="规则名称:">
              <el-input
                placeholder="规则名称"
                v-model="formData.Name"
                style="width: 200px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="对象类型:">
              <el-select
                v-model="formData.ObjectId"
                placeholder="请选择对象类型"
                style="width: 200px"
                clearable
              >
                <el-option
                  v-for="item in InternalList"
                  :key="item._id"
                  :label="item.Name"
                  :value="item._id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="共享者:">
              <el-select v-model="formData.ToGroupType" clearable placeholder="共享者" style="width: 200px">
                <el-option label="公用小组" value="公用小组"></el-option>
                <el-option label="角色" value="角色"></el-option>
                <el-option label="角色和下属" value="角色和下属"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="访问权限等级:">
              <el-select v-model="formData.AccessLevel" clearable placeholder="访问权限等级" style="width: 200px">
                <el-option label="只读" value="1"></el-option>
                <el-option label="读写" value="2"></el-option>
                <el-option label="所有" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-button size="medium" type="info" @click="handleQuery" round
              >应用</el-button
            >
          </el-form>
        </div>
        <div class="headOperation" v-if="!showQuery">
          <img
            @click="showQuery = !showQuery"
            class="showQuery"
            src="@/assets/img/query-more.png"
            alt=""
          />
        </div>
        <el-table :data="SharingRules" class="table-customer">
          <el-table-column label="规则名称" prop="Name">
            <template slot-scope="scope">
              <a class="g-link" @click="handleShowRules(scope.row)">
                {{ scope.row.Name }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="对象类型" prop="ObjectName"></el-table-column>
          <el-table-column
            label="共享者"
            prop="shareToInfoString"
          ></el-table-column>
          <el-table-column
            label="访问权限等级"
            prop="AccessLevelName"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="confirmDelete(scope.row)">
                <i class="iconfont icon-delete"></i>
              </el-button>
              <!-- <div class="tool-wrap">
                <el-button type="text" @click="handleEdit(scope.row)">
                  <i class="iconfont icon-edit"></i>
                </el-button>
                <el-dropdown @command="handleCommand($event, scope.row)">
                  <span class="el-dropdown-link">
                    <i class="iconfont icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="delete"
                      ><i class="iconfont icon-delete"></i
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :total="pagination.totalCount"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          v-if="pagination.totalCount"
          @current-change="handlePage"
        >
        </el-pagination>
      </cond-card>
    </div>
    <edit-drawer
      :exist.sync="settingVisible"
      ref="settingRef"
      @success="handleSuccessSetting"
    />
    <edit-drawer-rules
      v-if="rulesVisible"
      :exist.sync="rulesVisible"
      :saveType="saveType"
      :detail="choiceRule"
      :GroupTypes="GroupTypes"
      :Groups="Groups"
      :InternalList="InternalListRules"
      :AccessLevelList="AccessLevelList"
      :SharingRuleTypes="SharingRuleTypes"
      ref="ruleEditRef"
      @success="handleSuccessRule"
    />

    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该规则吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from "@/views/layout/content-header";
import condCard from "@/components/cond-card.vue";
import editDrawer from "./components/edit-drawer.vue";
import editDrawerRules from "./components/edit-drawer-rules.vue";

import { apiGetShareList, apiDeleteShare } from "@/api/auth";

export default {
  components: { ContentHeader, condCard, editDrawer, editDrawerRules },
  data() {
    return {
      showQuery: false,
      choiceRule: null, // 选择的规则
      title: this.$route.meta.title,
      settingVisible: false,
      rulesVisible: false,
      delConfirmShow: false,
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      loading: true,
      SharingRules: [], // 规则列表
      GroupTypes: [], // 角色分组列表 如：[{Label: '公用小组', Value: '公用小组'}]
      Groups: [], // 角色列表 ，新增时候确定角色列表，角色类型：当选择为公用小组时，Type !== ['Role', 'RoleAndSubordinates']；为角色时，Type === ['Role']；为角色和下属时， Type === ['RoleAndSubordinates']
      InternalList: [], // 对象列表（包含字段信息）；规则新增时候，对象列表确定：对象类型列表去掉 ：子对象（HasMasterDetail === 1）和 OWDInternal === 'PublicReadWrite'  两种情况。  编辑对象权限时候，子对象（HasMasterDetail === 1）不能编辑权限。
      InternalOptions: [], // 权限组列表 ， 如 [{Label: '公用读', Value: 'PublicReadOnly'}]
      SharingRuleTypes: [], // 共享分类，如: [{Label: '基于条件', Value: '基于条件'}]
      AccessLevelList: [], // 访问权限列表，如：[{Label: '只读', Value: '1'}]
      saveType: "add", // add ,edit, show
      InternalListRules: [], // 新增和编辑规则使用的对象列表，根据 this.InternalList  筛选
      formData: {
        Name: "", // 规则名称
        ObjectId: "", // 对象类型
        ToGroupType: "", // 共享者
        AccessLevel: "", // 访问权限等级
      },
    };
  },
  methods: {
    handleQuery() {
      this.handlePage(1);
    },
    // 编辑共享设置
    handleEditSetting() {
      this.settingVisible = true;
      if (this.$refs.settingRef) {
        this.$refs.settingRef.setFormData(
          JSON.parse(JSON.stringify(this.InternalList)),
          this.InternalOptions
        );
      }
    },
    // 新增共享规则
    handleAddRules() {
      this.rulesVisible = true;
      this.saveType = "add";
    },
    // 查看共享规则
    handleShowRules(detail) {
      this.choiceRule = detail;
      this.rulesVisible = true;
      this.saveType = "show";
      this.$nextTick(() => {
        this.$refs.ruleEditRef && this.$refs.ruleEditRef.getDetail();
      });
    },
    handleCommand(cmd, detail) {
      this.choiceRule = detail;
      switch (cmd) {
        case "delete":
          this.delConfirmShow = true;
          break;
      }
    },
    confirmDelete(detail) {
      this.choiceRule = detail;
      this.delConfirmShow = true;
    },
    handleDelete() {
      if (!this.choiceRule) {
        return;
      }
      apiDeleteShare(this.choiceRule._id).then(() => {
        this.delConfirmShow = false;
        this.SharingRules = this.SharingRules.filter((item) => {
          return item._id !== this.choiceRule._id;
        });
        if (!this.SharingRules.length) {
          this.pagination.currentPage = 1;
          this.getShareList();
        }
      });
    },
    handleEdit() {
      this.rulesVisible = true;
    },
    // 编辑对象权限成功
    handleSuccessSetting() {
      this.settingVisible = false;
      this.getShareList();
    },
    // 新增编辑共享规则成功
    handleSuccessRule() {
      this.rulesVisible = false;
      if (this.saveType === "add") {
        this.pagination.currentPage = 1;
      }
      this.getShareList();
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getShareList();
    },
    getShareList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      apiGetShareList(param)
        .then((res) => {
          this.SharingRuleTypes = res.SharingRuleTypes || [];
          this.SharingRuleTypes.reverse();
          this.InternalOptions = res.InternalOptions || [];
          this.AccessLevelList = res.AccessLevelList || [];
          this.GroupTypes = res.GroupTypes || [];
          this.InternalList = res.InternalList || [];
          this.Groups = res.Groups || [];
          // 对象类型列表去掉 ：子对象（HasMasterDetail === 1）和 OWDInternal === 'PublicReadWrite'  两种情况。
          this.InternalListRules = [];

          this.InternalList.forEach((item) => {
            if (
              item.HasMasterDetail !== 1 &&
              item.OWDInternal !== "PublicReadWrite"
            ) {
              this.InternalListRules.push(item);
            }

            if (item.HasMasterDetail === 1) {
              // 子对象不能编辑对象权限
              item.disabledEdit = true;
            }
            const findInternal = this.InternalOptions.find((obj) => {
              return obj.Value === item.OWDInternal;
            });
            if (findInternal) {
              // 内部默认访问权限名称
              item.InternalName = findInternal.Label;
            } else {
              item.InternalName = "-";
            }
          });
          const list = res.SharingRules || [];
          list.forEach((item) => {
            const findObj = this.InternalList.find((obj) => {
              return obj._id === item.ObjectId;
            });
            if (findObj) {
              // 对象类型名称
              item.ObjectName = findObj.Name;
            }
            const findAccessLevel = this.AccessLevelList.find((obj) => {
              return obj.Value === item.AccessLevel;
            });
            if (findAccessLevel) {
              // 访问权限等级名称
              item.AccessLevelName = findAccessLevel.Label;
            } else {
              item.AccessLevelName = "-";
            }
            // 要共享的记录（成员） FromGroupType =》 GroupTypes  ： SharedFromName

            // 共享者信息（成员） ToGroupType =》GroupTypes： SharedToName
            const findGroupType = this.GroupTypes.find((obj) => {
              return obj.Value === item.ToGroupType;
            });
            if (findGroupType) {
              item.shareToTypeName = findGroupType.Label;
            }

            const findGroup = this.Groups.find((obj) => {
              return obj.Value === item.SharedTo;
            });
            if (findGroup) {
              item.groupToName = findGroup.Label;
            }

            if (item.shareToTypeName && item.groupToName) {
              // 共享者
              item.shareToInfoString = `${item.shareToTypeName}：${item.groupToName}`;
            } else {
              item.shareToInfoString = "-";
            }
          });
          this.SharingRules = list;
          this.GroupTypes = res.GroupTypes || [];

          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getShareList();
  },
};
</script>

<style lang="less" scoped>
.tip {
  font-size: 12px;
  line-height: 19px;
  color: #747378;
  margin-top: -6px;
  margin-bottom: 14px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &-title {
    color: #26262c;
    font-weight: 500;
    font-size: 20px;
  }
  &-btn {
  }
}
.tool-wrap {
  /deep/ i {
    font-size: 20px;
    // margin-right: 20px;
    color: #9a9a9e;
  }
}
.g-link {
  cursor: pointer;
}

.query-form {
  background: #f6f7f8;
  border-radius: 6px;
  position: relative;
  padding: 30px 90px 6px 16px;
  margin: 0 0 20px 0;
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  /deep/ .el-button--info {
    position: absolute;
    right: 10px;
    bottom: 16px;
    background: #4d4b58;
  }
}

.headOperation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .showQuery {
    cursor: pointer;
  }
}
</style>
