const util = {
  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  },
  formatDate(date, fmt = "YYYY-MM-DD HH:mm:ss") {
    if (!date) {
      return date;
    }
    if (typeof date === "string") {
      date = new Date(date);
    }
    if (typeof date === "number") {
      date = new Date(date);
    }
    const o = {
      "M+": date.getMonth() + 1,
      "D+": date.getDate(),
      "h+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
      "H+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    const week = {
      0: "\u65e5",
      1: "\u4e00",
      2: "\u4e8c",
      3: "\u4e09",
      4: "\u56db",
      5: "\u4e94",
      6: "\u516d",
    };
    if (/(Y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
      );
    }
    if (/(E+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (RegExp.$1.length > 1
          ? RegExp.$1.length > 2
            ? "\u661f\u671f"
            : "\u5468"
          : "") + week[`${date.getDay()}`]
      );
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
        );
      }
    }
    return fmt;
  },
  getQuery() {
    const url = window.location.href;
    const qsStr = url.split("?")[1] || "";
    const qs = qsStr.split("&").reduce((res, qsItemStr) => {
      const [key, value] = qsItemStr.split("=");
      return {
        ...res,
        [key]: value,
      };
    }, {});
    return qs;
  },
  convertImgToBase64(url, callback, outputFormat) {
    let canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL(outputFormat || "image/png");
      callback.call(this, dataURL);
      canvas = null;
    };
    img.src = url;
  },
  /**
   * 图片压缩
   * @param {*图片内容} base64
   * @param {*目标图片宽度} width
   * @param {*目标图片高度} height
   */
  async compressImg(base64, width = 1, height = 1) {
    let canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = base64;
    return new Promise((resolve) => {
      img.onload = function () {
        const ratio = Math.max(img.width / width, img.height / height) * 0.5;
        console.log(`width=${width} ; height=${width} ; ratio=${ratio}`);
        canvas.height = img.height / ratio;
        canvas.width = img.width / ratio;
        ctx.drawImage(img, 0, 0, img.width / ratio, img.height / ratio);
        var dataURL = canvas.toDataURL("image/png");
        canvas = null;
        resolve(dataURL);
      };
    });
  },
  randomString(len) {
    len = len || 24;
    const $chars =
      "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    const maxPos = $chars.length;

    let pwd = "";
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  },
  autoResizeImg(maxWidth, maxHeight, width, height) {
    const wRatio = maxWidth / width;
    const hRatio = maxHeight / height;
    let ratio = 1;
    if (maxWidth === 0 && maxHeight === 0) {
      ratio = 1;
    } else if (maxWidth === 0) {
      if (hRatio < 1) {
        ratio = hRatio;
      }
    } else if (maxHeight === 0) {
      if (wRatio < 1) {
        ratio = wRatio;
      }
    } else if (wRatio < 1 || hRatio < 1) {
      ratio = wRatio <= hRatio ? wRatio : hRatio;
    }

    return {
      width: width * ratio,
      height: height * ratio,
    };
  },
  // 筛选条件逻辑规则校验---- FilterConditionType === 'Custom' ，用户手动输入的值规则：()  必须成对， 必须包含至少一个 数字，  两个数字之间必须有 逻辑运算符值， 值之间必须有空格。如：(1 OR 2) AND (3 OR 4) 。 正式规则： 只能填写：整数、英文左括号、英文右括号、AND、OR、空格
  // numsArr = [1,2,3] 数字必须在这个里面
  // filterConditionLogicRule(value, numsArr) {
  //   let bool = true;
  //   const reg = /[\d|\(|\)|AND|OR]/; // /(\d+)&(\(+)|(\)+)|(AND+)|(OR+)|(\s+)/
  //   if (value) {
  //     bool = reg.test(value);
  //   }
  //   return bool;
  // },
};

export default util;
