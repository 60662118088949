<template>
  <div class="content-header">
    <el-breadcrumb slot="breadcrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <i class="el-icon-s-home item-icon" />
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="item in breadList" :key="item">
        <span class="item-text">{{ item }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-header-row">
      <div class="left">
        <div class="title">
          {{ title }}<slot name="title" class="title"></slot>
        </div>
      </div>
      <div class="right">
        <slot name="tools"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    breadList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.content-header {
  height: 83px;
  margin-bottom: 26px;
  border-bottom: 1px solid #dddddf;
  .content-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    .title {
      height: 36px;
      margin: 12px 0 18px 0;
      line-height: 36px;
      font-size: 34px;
      color: #26262c;
    }
    .item-icon {
      font-size: 16px;
      color: #9a9a9e;
    }
    .item-text {
      color: #747378;
    }
  }
  .right {
    flex: 1;
    text-align: right;
  }
}
</style>
