<template>
  <div>
    <content-header
      :title="detail.Name"
      :breadList="['模型中心', '预测评分类模型']"
    >
      <template #tools>
        <!-- <el-button type="primary" round>
          <div class="button" @click="install">
            <img src="@/assets/svg/download.svg" alt="" />
            安装
          </div>
          
        </el-button> -->
        <div class="button-box-box" v-if="!installStatus">
          <el-button
            type="primary"
            round
            v-if="detail.Action === 'Install'"
            @click="install"
          >
            <div class="button-box">
              <img src="@/assets/svg/download.svg" alt="" />
              安装
            </div>
          </el-button>
          <el-button type="primary" round v-if="detail.Action === 'Upgrade'">
            <div class="button-box">
              <img src="@/assets/svg/refresh-white.svg" alt="" />
              更新
            </div>
          </el-button>
          <div class="installed-box" v-if="detail.Action === ''">
            <el-button
              type="info"
              round
              v-if="detail.Action === ''"
              class="installed"
            >
              <div class="button-box installed">
                <img src="@/assets/svg/done.png" alt="" />
                已安装
              </div>
            </el-button>
            <!-- <el-button
              type="default"
              round
              v-if="detail.Action === ''"
              class="uninstall"
              @click="uninstall"
              >卸载
            </el-button> -->
          </div>
        </div>
        <div v-else>
          <el-button type="text" class="update-status" v-if="installStatus == 1"
            >正在检查数据...</el-button
          >
          <el-button
            type="text"
            class="update-status"
            loading
            v-if="installStatus == 2"
            >正在安装...</el-button
          >
        </div>
      </template>
    </content-header>

    <div class="img-wrap">
      <img :src="detail.BigImage" alt="" />
    </div>

    <div class="info-wrap">
      <div class="info-item">
        <div class="info-item-label">类型</div>
        <div class="info-item-value">{{ detail.Subtitle }}</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">运行环境</div>
        <div class="info-item-value">{{ detail.Env }}</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">计算引擎</div>
        <div class="info-item-value">{{ detail.Engine }}</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">运行频率</div>
        <div class="info-item-value">{{ detail.Freq }}</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">版权</div>
        <div class="info-item-value">{{ detail.Copyright }}</div>
      </div>
    </div>

    <div class="version">
      <div class="version-wrap" v-loading="loading">
        <div class="version-left">
          <div class="version-label">新功能</div>
          <div
            :class="['version-desc', { 'version-desc-more': more }]"
            v-html="detail.ReleaseDesc"
          ></div>
          <div class="more" @click="more = true" v-if="!more">
            更多
            <img src="@/assets/svg/arrow-right.svg" alt="" />
          </div>
          <div class="more" @click="more = false" v-else>
            收起
            <img
              src="@/assets/svg/arrow-right.svg"
              alt=""
              style="transform: rotate(-90deg)"
            />
          </div>
        </div>
        <div class="version-right">
          <div class="version-label2" @click="handelVersion">历史版本记录</div>
          <div class="version-date">{{ detail.Time }}</div>
          <div class="version-value">版本 V{{ detail.CloudVersion }}</div>
        </div>
      </div>
    </div>
    <div class="desc-iframe">
      <iframe
        :src="detail.DescURL"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <!-- <img
      src="@/assets/linshi/img1.svg"
      alt=""
      style="width: calc(100% + 28px); margin-left: -14px; margin-top: 24px"
    />
    <img
      src="@/assets/linshi/img2.svg"
      alt=""
      style="width: calc(100% + 28px); margin-left: -14px; margin-top: 0px"
    /> -->

    <el-drawer
      :visible.sync="visible"
      direction="rtl"
      :show-close="false"
      :size="1020"
      v-loading="drawerLoading"
      :before-close="handleClose"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>版本历史记录</span>
        </div>
      </div>
      <div class="version-list">
        <div
          class="version-list-item"
          v-for="(item, index) in versionList"
          :key="index"
        >
          <div class="version-list-item-header">
            <div class="version-list-item-header-value">
              V{{ item.CloudVersion }}
            </div>
            <div class="version-list-item-header-date">{{ item.Time }}</div>
          </div>
          <div class="version-list-item-desc" v-html="item.ReleaseDesc"></div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import ContentHeader from "@/views/layout/content-header";
import { apiGetModelsDetail, apiUploadModel } from "@/api/model-center";
import { apiGetCampaignPermission } from "@/api/auth";
export default {
  components: {
    ContentHeader,
  },
  data() {
    return {
      visible: false,
      title: "",
      drawerLoading: false,
      more: false,
      detail: {},
      loading: false,
      versionList: [],
      installStatus: 0,
    };
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    handelVersion() {
      this.visible = true;
    },
    install() {
      // 请求编辑权限
      apiGetCampaignPermission(this.$route.query.id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.installStatus = 1;
          setTimeout(() => {
            this.installStatus = 2;
          }, 500);
          apiUploadModel(this.$route.query.id)
            .then(() => {
              this.detail.Action = "";
            })
            .finally(() => {
              this.installStatus = 0;
            });
        } else {
          this.$message.error("无编辑权限");
        }
      });
    },
    uninstall() {
      this.detail.Action = "Install";
    },
  },
  created() {
    this.loading = true;
    apiGetModelsDetail(this.$route.query.id).then((res) => {
      this.loading = false;
      this.detail = res;
      this.versionList = res.Versions;
    });
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-button.is-round {
  padding: 8px 18px;
}
/deep/ .el-button--info {
  background-color: #4d4b58;
}
.button-box {
  display: flex;
  align-items: center;
  button {
    padding: 8px 14px;
  }
  img {
    margin-right: 4px;
  }
}
.installed-box {
  display: inline-block;
  width: 100px;
}
.uninstall {
  width: 100px;
  display: none;
  margin: 0;
}
// .installed-box:hover {
//   .uninstall {
//     display: block;
//   }
//   .installed {
//     display: none;
//   }
// }
.button {
  display: flex;
  align-items: center;
  button {
    padding: 8px 14px;
  }
  img {
    margin-right: 4px;
  }
}
.img-wrap {
  text-align: center;
  margin-bottom: 26px;
  img {
    width: 356px;
  }
}
.info-wrap {
  display: flex;
  height: 60px;
  align-items: center;
}
.info-item {
  flex: 1;
  text-align: center;
  color: #747378;
  &-label {
    font-size: 10px;
    margin-bottom: 4px;
  }
  &-value {
    font-size: 16px;
    line-height: 25px;
  }
}
.info-item + .info-item {
  border-left: 1px solid #dddddf;
}
.version {
  margin-top: 32px;
  padding: 20px;
  background: #ffffff;
  /* M - 04 dp - card */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  &-wrap {
    display: flex;
    justify-content: space-between;
  }
  &-label {
    color: #26262c;
    font-size: 20px;
  }
  &-label2 {
    color: #0483ec;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
  &-desc {
    color: #747378;
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
    height: 66px;
    overflow: hidden;
  }
  &-desc-more {
    height: auto;
  }
  &-date {
    color: #747378;
    font-size: 12px;
    line-height: 19px;
    margin-top: 28px;
    text-align: right;
  }
  &-value {
    color: #747378;
    font-size: 12px;
    line-height: 19px;
    text-align: right;
  }
  .more {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #0483ec;
    cursor: pointer;
    margin-top: 16px;
    img {
      margin-left: 6px;
    }
  }
}

.desc-iframe {
  margin-top: 32px;
  // padding: 20px;
  background: #ffffff;
  /* M - 04 dp - card */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  iframe {
    width: 100%;
    height: 1957px;
    border-radius: 12px;
  }
}

/deep/ .el-drawer {
  background: #f6f7f8;
  .el-drawer__header {
    margin-bottom: 22px;
    padding: 0;
  }
  .el-drawer__body {
    padding: 0 22px;
    overflow: auto;
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 30px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.06);
  .left {
    font-size: 24px;
    line-height: 32px;
    i {
      margin-right: 22px;
      cursor: pointer;
    }
  }
}
.version-list {
  &-item {
    padding: 25px 28px;
    background: #ffffff;
    /* M - 24 dp - modal */

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 24px 36px rgba(0, 0, 0, 0.04), 0px 16px 32px rgba(0, 0, 0, 0.04),
      0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-bottom: 22px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-value {
        color: #26262c;
        font-size: 16px;
        line-height: 26px;
      }
      &-date {
        color: #747378;
        font-size: 12px;
        line-height: 19px;
      }
    }
    &-desc {
      margin-top: 16px;
      font-size: 14px;
      line-height: 22px;
      color: #26262c;
    }
  }
}
</style>
