<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :size="1000"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add" ? "新建共享规则" : "查看共享规则"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            v-if="!disabled"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <div class="textTip">
          使用共享规则以对定义的用户集对组织范围的共享设置进行自动例外。
          注意:“角色和下属"包括特定角色中的所有用户或该角色下的角色。<br />
          您只能使用共享规则授予对数据较宽的访问权限,而不能限制访问权限。
        </div>
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="110px"
        >
          <!-- 规则名 -->
          <cond-card title="规则名">
            <el-form-item prop="Name" label="规则名:">
              <el-input
                v-model="formData.Name"
                placeholder="请输入规则名"
                :disabled="disabled"
                class="inputs"
              ></el-input>
            </el-form-item>
            <el-form-item prop="ObjectId" label="对象类型:">
              <el-select
                v-model="formData.ObjectId"
                :disabled="disabled"
                class="inputs"
                @change="handleChangeObjectId"
                filterable
              >
                <el-option
                  v-for="item in InternalList"
                  :key="item._id"
                  :label="item.Name"
                  :value="item._id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="描述:" prop="Description">
              <el-input
                type="textarea"
                v-model="formData.Description"
                :disabled="disabled"
                placeholder="请输入描述"
                class="inputs"
              ></el-input>
            </el-form-item>
          </cond-card>

          <!-- 选择规则类型 -->
          <cond-card title="选择规则类型">
            <el-form-item label="规则类型:">
              <el-radio-group v-model="formData.RuleType" :disabled="disabled">
                <el-radio
                  v-for="item in SharingRuleTypes"
                  :key="item.Value"
                  :label="item.Value"
                  >{{ item.Label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </cond-card>

          <!-- 选择要共享的记录 基于记录所有人-->
          <cond-card
            title="选择要共享的记录"
            v-if="formData.RuleType === '基于记录所有人'"
          >
            <div class="selectBox">
              <el-form-item
                prop="FromGroupType"
                label="由下列成员拥有:"
                class="one"
              >
                <el-select
                  v-model="formData.FromGroupType"
                  :disabled="disabled"
                  @change="handleFromGroupType"
                  filterable
                >
                  <el-option
                    v-for="item in GroupTypes"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="SharedFrom"
                label=""
                class="two"
                label-width="0"
                ref="sharedFromRef"
              >
                <el-select
                  v-model="formData.SharedFrom"
                  :disabled="disabled"
                  filterable
                >
                  <el-option
                    v-for="item in groupsListFrom"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </cond-card>

          <!-- 选择要共享的记录 基于条件-->
          <cond-card
            title="选择要共享的记录"
            v-if="formData.RuleType === '基于条件'"
          >
            <div class="record-condition">
              <cond-item
                v-for="(item, index) in condCustList"
                :ref="item._id"
                :key="item._id"
                :detail="item"
                :index="index"
                :fields="field"
                @remove="handleRemoveCondCust"
                :disabled="disabled"
              ></cond-item>
            </div>
            <div style="margin-left: 44px">
              <el-button
                type="text"
                @click="handleAddCondCust"
                :disabled="disabled"
                >+ 添加条件</el-button
              >
            </div>
            <div class="filter-box">
              <el-form-item
                label="筛选条件要求"
                label-width="84px"
                prop="FilterConditionType"
              >
                <el-select
                  v-model="formData.FilterConditionType"
                  :disabled="disabled"
                  style="width: 200px"
                >
                  <el-option
                    label="同时满足所有条件（AND）"
                    value="AND"
                  ></el-option>
                  <el-option
                    label="满足其中任一条件（OR）"
                    value="OR"
                  ></el-option>
                  <el-option label="自定义逻辑" value="Custom"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="筛选条件逻辑"
                label-width="84px"
                prop="FilterConditionLogic"
              >
                <el-input
                  v-model="formData.FilterConditionLogic"
                  placeholder="请输入筛选条件逻辑"
                  :disabled="
                    formData.FilterConditionType !== 'Custom' || disabled
                  "
                ></el-input>
              </el-form-item>
            </div>
          </cond-card>

          <!-- 选择要与之共享的用户 -->
          <cond-card title="选择要与之共享的用户">
            <div class="selectBox">
              <el-form-item
                prop="ToGroupType"
                label="与之共享的用户:"
                class="one"
              >
                <el-select
                  v-model="formData.ToGroupType"
                  @change="handleToGroupType"
                  :disabled="disabled"
                  filterable
                >
                  <el-option
                    v-for="item in GroupTypes"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="SharedTo"
                label=""
                class="two"
                label-width="0"
                ref="sharedToRef"
              >
                <el-select
                  v-model="formData.SharedTo"
                  :disabled="disabled"
                  filterable
                >
                  <el-option
                    v-for="item in groupsListTo"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </cond-card>

          <!-- 选择用户的访问权限等级 -->
          <cond-card title="选择用户的访问权限等级">
            <el-form-item prop="AccessLevel" label="访问权限:">
              <el-select
                v-model="formData.AccessLevel"
                :disabled="disabled"
                filterable
              >
                <el-option
                  v-for="item in AccessLevelList"
                  :key="item.Value"
                  :label="item.Label"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import condCard from "@/components/cond-card";
import condItem from "@/components/cond-item";
import util from "@/util";
import { apiCreateShareRules, apiGetShareDetail } from "@/api/auth";

const excludesAPIName = [
  "OwnerId",
  "CreatedById",
  "CreatedDate",
  "LastModifiedById",
  "LastModifiedDate",
  "IsDeleted",
  "DataShare",
];
const excludesDataType = [
  "Image",
  "MultiImage",
  "Lookup",
  "MasterDetail",
  "AutoNumber",
  "Formula",
  "RollupSummary",
];
const filterField = (list) => {
  return list
    .filter((item) => {
      return !excludesAPIName.includes(item.APIName);
    })
    .filter((item) => {
      return !excludesDataType.includes(item.DataType);
    });
};

export default {
  components: {
    condCard,
    condItem,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    GroupTypes: [], // 角色分组列表 如：[{Label: '公用小组', Value: '公用小组'}]
    Groups: [], // 角色列表 ，新增时候确定角色列表，角色类型：当选择为公用小组时，Type !== ['Role', 'RoleAndSubordinates']；为角色时，Type === ['Role']；为角色和下属时， Type === ['RoleAndSubordinates']
    SharingRuleTypes: [], // 共享分类，如: [{Label: '基于条件', Value: '基于条件'}]
    InternalList: [], // 对象列表，每个对象包含字段列表
    AccessLevelList: [], // 访问权限列表，如：[{Label: '只读', Value: '1'}]
  },
  computed: {
    field() {
      const obj = this.InternalList.find(
        (item) => item._id === this.formData.ObjectId
      );
      const fileds = (obj && obj._Fields) || [];
      return filterField(fileds);
    },
    groupsListFrom() {
      const list = [];
      this.Groups.forEach((item) => {
        if (
          this.formData.FromGroupType === "公用小组" &&
          ["Role", "RoleAndSubordinates"].indexOf(item.Type) === -1
        ) {
          list.push(item);
        }
        if (
          this.formData.FromGroupType === "角色" &&
          ["Role"].indexOf(item.Type) > -1
        ) {
          list.push(item);
        }
        if (
          this.formData.FromGroupType === "角色和下属" &&
          ["RoleAndSubordinates"].indexOf(item.Type) > -1
        ) {
          list.push(item);
        }
      });

      return list;
    },
    groupsListTo() {
      const list = [];
      this.Groups.forEach((item) => {
        if (
          this.formData.ToGroupType === "公用小组" &&
          ["Role", "RoleAndSubordinates"].indexOf(item.Type) === -1
        ) {
          list.push(item);
        }
        if (
          this.formData.ToGroupType === "角色" &&
          ["Role"].indexOf(item.Type) > -1
        ) {
          list.push(item);
        }
        if (
          this.formData.ToGroupType === "角色和下属" &&
          ["RoleAndSubordinates"].indexOf(item.Type) > -1
        ) {
          list.push(item);
        }
      });

      return list;
    },
    filterTxt() {
      const { condCustList, formData } = this;

      if (formData.FilterConditionType === "Custom") {
        return formData.FilterConditionLogic;
      }
      const count = condCustList.length;

      let result = "";
      for (let i = 1; i <= count; i++) {
        result += i;
        result += i === count ? "" : ` ${formData.FilterConditionType} `;
      }
      return result;
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Name: "", // 规则名称
        ObjectId: "", // 对象类型
        Description: "", // 描述
        RuleType: "基于记录所有人", // 规则类型：基于记录所有人、 基于条件
        FromGroupType: "", // 要共享的成员类型
        SharedFrom: "", // 要共享的成员
        ToGroupType: "", // 被共享的成员类型
        SharedTo: "", // 被共享的成员
        AccessLevel: "", // 访问权限
        FilterConditionType: "AND",
        FilterConditionLogic: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入规则名",
          trigger: "blur",
        },
        ObjectId: {
          required: true,
          message: "请选择对象类型",
          trigger: "change",
        },
        RuleType: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        FromGroupType: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        SharedFrom: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        ToGroupType: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        SharedTo: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        AccessLevel: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
      },
      condCustList: [],
      disabled: false,
    };
  },
  watch: {
    // exist(newVal) {
    //   if (newVal === true) {
    //     // 打开请求详情
    //     this.getDetail();
    //   } else {
    //     // 关闭重置表单值
    //     this.resetForm();
    //   }
    // },
    filterTxt(newVal) {
      this.formData.FilterConditionLogic = newVal;
    },
  },
  methods: {
    handleChangeObjectId() {
      this.condCustList = [];
    },
    // 切换
    handleFromGroupType() {
      this.$refs.sharedFromRef.resetField();
    },
    handleToGroupType() {
      this.$refs.sharedToRef.resetField();
    },
    handleRemoveCondCust(detail) {
      const index = this.condCustList.indexOf(detail);
      this.condCustList.splice(index, 1);
    },
    handleAddCondCust() {
      if (!this.formData.ObjectId) {
        return;
      }
      this.condCustList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    // 关闭弹窗
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.validate();
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    validate() {
      const validList = [...this.condCustList];

      let valid = true; // 是否验证通过
      validList.forEach((item) => {
        let row = this.$refs[item._id] || [];
        row = row[0];
        if (row) {
          valid = row.validate() === false ? false : valid;
        }
      });

      return valid;
    },
    // 提交
    submitForm() {
      if (!this.validate()) {
        return false;
      }
      this.saving = true;
      const param = {
        ...this.formData,
        FilterItems: this.condCustList,
      };
      apiCreateShareRules(param)
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    // 重置表单值
    resetForm() {
      this.$refs.form.resetFields();
    },
    // 详情
    getDetail() {
      this.disabled = false;
      if (this.saveType === "show") {
        this.disabled = true;
        apiGetShareDetail(this.detail._id).then((res) => {
          const SharingRule = res.SharingRule || {};
          Object.assign(this.formData, {
            Name: SharingRule.Name, // 规则名称
            ObjectId: SharingRule.ObjectId, // 对象类型
            Description: SharingRule.Description, // 描述
            RuleType: SharingRule.RuleType, // 规则类型：基于记录所有人、 基于条件
            FromGroupType: SharingRule.FromGroupType, // 要共享的成员类型
            SharedFrom: SharingRule.SharedFrom, // 要共享的成员
            ToGroupType: SharingRule.ToGroupType, // 被共享的成员类型
            SharedTo: SharingRule.SharedTo, // 被共享的成员
            AccessLevel: SharingRule.AccessLevel, // 访问权限
            FilterConditionType: SharingRule.FilterConditionType,
            FilterConditionLogic: SharingRule.FilterConditionLogic,
          });
          this.condCustList = SharingRule.filterItems;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  .textTip {
    margin-bottom: 22px;
    margin-top: 10px;
    color: #747378;
    width: 385px;
    line-height: 19px;
    font-size: 12px;
  }
  /deep/ .el-drawer {
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }
  .record-condition {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .index {
        width: 22px;
        height: 22px;
        background: #f6f7f8;
        border-radius: 50%;
        text-align: center;
        line-height: 22px;
        color: #26262c;
        font-size: 11px;
        margin-right: 13px;
      }
    }
  }
  .filter-box {
    background: #f6f7f8;
    border-radius: 6px;
    padding: 20px 20px 6px 20px;
  }
  .selectBox {
    display: flex;

    .one {
      width: 300px;
      margin-right: 10px;
    }

    .two {
      width: 300px;
    }
  }
  .inputs {
    width: 325px;
  }
}
</style>
